// import logo from './logo.svg';
// import './App.css';
import { Fragment } from 'react';
import { makeStyles, Box, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  Article: {
    height: (props) => (props.height == '' ? '' : '100vh'),

    [theme.breakpoints.down('lg')]: {
      minHeight: (props) =>
        props.minHeight || props.minHeight == 0 ? props.minHeight : '800px',
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: '700px',
    },

    // minHeight: (props) =>
    //   props.minHeight || props.minHeight == 0 ? props.minHeight : '800px',
    // backgroundColor: (props) => props.background,
    position: (props) => props.position,

    // marginBottom: '50px'
  },
}));
const Article = (props) => {
  const classes = useStyles(props);
  const classes2 = props.useStyles ? props.useStyles() : {};
  // console.log(classes2);
  return (
    <Box bgcolor={props.background} style={{ boxShadow: `${props.boxShadow}` }}>
      <Container maxWidth={props.width}>
        <Box
          // className={props.className}
          id={props.id}
          component='article'
          className={`${classes.Article} ${classes2.container || ''}`}
        >
          {props.children}
        </Box>
      </Container>
    </Box>
  );
};

export default Article;
