// import logo from './logo.svg';
import './App.css';
// import { Fragment, useEffect } from 'react';
import React, { Fragment, useState } from 'react';
import Appbar from './Appbar';
import Header from './Header';
import Projects from './Projects';
import AboutMe from './AboutMe';
import Contact from './Contact';
// import './fonts/Regular.woff';
import Footer from './Footer';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import Addition from './Addition';
import Reveal from 'react-reveal/Reveal';

import { Container } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#444',
    },
    secondary: {
      main: '#AC3B61',
    },
    success: {
      main: '#123C69',
    },
  },
  shadows: [0],
  typography: {
    // htmlFontSize: 20,
    subtitle1: {
      fontFamily: ['PT Sans', 'sans-serif'].join(','),
    },
    subtitle2: {
      fontFamily: ['PT Sans', 'sans-serif'].join(','),
    },
    body1: {
      fontFamily: ['PT Sans', 'sans-serif'].join(','),
    },
    body2: {
      fontFamily: ['PT Sans', 'sans-serif'].join(','),
    },

    fontFamily: ['Open Sans', 'sans-serif'].join(','),

    h6: {
      fontFamily: ['PT Sans', 'sans-serif'].join(','),

      // fontFamily: ['Courier New', 'sans-serif'].join(','),
    },
    button: {
      textTransform: 'none',
    },
  },
});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const App = () => {
  const [view, setView] = useState('About');

  const menuClicked = (e) => {
    // e.currenttarget.innerHTML
    setView(e.target.innerHTML);
  };

  return (
    <main>
      <ThemeProvider theme={theme}>
        {/* <Container maxWidth='lg'> */}
        <Appbar />
        {/* <div style={{ padding: '2000px 0px' }}></div> */}
        <Addition />
        <Header />
        {/* </Container> */}
        {/* <Container maxWidth='md'> */}
        {/* <div style={{ position: 'sticky', top: 0 }}>dfvdfvdfvdvf</div> */}
        <AboutMe />

        {/* <AboutMe /> */}
        <Projects />
        {/* <Experience /> */}
        <Contact />
        {/* {view === 'About' && <AboutMe />} */}
        {/* {view === 'Projects' && <Projects />} */}
        {/* {view === 'Contact' && <Contact />} */}
        {/* <Footer /> */}
        {/* </Container> */}
        <Footer />
      </ThemeProvider>
    </main>
  );
};

export default App;
