// import './Aboutme.css';

import { Fragment } from 'react';
import { makeStyles, Typography, Box, Grid, Button } from '@material-ui/core';
import Article from './Article';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Avatar from '@material-ui/core/Avatar';
import imageAvidMath from './images/avidmath.png';
import imageAuxilium from './images/auxilium.png';
import imageScholarVid from './images/ScholarVid.png';
import imagechat from './images/Chatbloom.jpg';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ProjectItemRight from './ProjectItemRight';
import ProjectItemLeft from './ProjectItemLeft';
import Reveal from 'react-reveal/Reveal';

const useStyles = makeStyles((theme) => ({
  Name: { whiteSpace: 'nowrap' },
  // '@keyframes example': {
  //   '0%': { transform: 'rotate(0deg)' },
  //   '100%': { transform: 'rotate(720deg)' },
  // },
  // '@keyframes example2': {
  //   '0%': { transform: 'scale(1)' },
  //   '70%': { transform: 'scale(1.9)' },

  //   '100%': { transform: 'scale(1)' },
  // },
  media: {
    height: 0,
    paddingTop: '56.9%', // 16:9
    backgroundSize: 'contain',
    // opacity: '20%',
    // transition: 'opacity linear 0.5s',
    // transition: ' 0.70s',
    // transition: ' 0.70s',
    // animationName: 'example',
    // animationDuration: '4s',
    // animation: '6s $example2 infinite ',

    '&:hover': {
      // animation: '0.7s $example',
      // animation: rotate 1s, spin 3s;
      // animationTimingFunction: 'ease',
      // transform: 'rotate(20deg)',
      // transition: ' 0.7s',
    },
  },
  container: { position: 'relative', maxHeight: '120vh' },
  child: {
    padding: '100px 0',
    [theme.breakpoints.down('sm')]: { padding: '80px 0' },
    // position: 'absolute',
    // top: '50%',
    // transform: 'translateY(-50%)',
    '& li': {
      listStyleType: 'none',
      margin: 0,
      padding: '0',
      color: '#707070',
    },
    // textAlign: 'center',

    [theme.breakpoints.down('md')]: {},
    // [theme.breakpoints.up('lg')]: {
    //   bottom: '0',
    // },
  },
  button: {
    marginTop: '15px',
    padding: '6px 10px',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(100%)',
    },
  },
  aboutme: {
    letterSpacing: 'calc(-2px - 0.1vw)',
    paddingLeft: '10px',
  },
  title: {
    [theme.breakpoints.up('md')]: {
      '&:after': {
        top: '-29px',
        width: '40vw',
        height: '1px',
        content: "''",
        display: 'block',
        position: 'relative',
        marginLeft: '433px',
        backgroundColor: '#c3c3c3',
      },
    },
  },
  sup: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingLeft: '3px',
  },
  iconColor: {
    color: theme.palette.secondary.light,
  },
  list: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  numerical: {
    fontFamily: 'Times New Roman',
  },
  projectImage: {
    // background: `url(${image})`,
    // '&:hover': {
    //   background: ` url(${image})`,
    //   backgroundColor: `green`,
    //   transition: 'all 0.5s',
    //   backgroundSize: 'cover',
    //   borderRadius: '20px',
    // },
    backgroundSize: 'cover',
    borderRadius: '20px',
    height: 0,
    paddingTop: '56.9%', // 16:9
  },
  backgroundColor: {
    height: '100%',
    width: '100%',
    backgroundColor: '#05386b',
    opacity: '20%',
    position: 'absolute',
    borderRadius: '20px',
    transition: 'all 0.5s',

    '&:hover': {
      opacity: '60%',
      transition: 'all 0.5s',
    },
  },
  projectDesc: {
    // position: 'relative',
    minWidth: '130%',
    backgroundColor: '#05386b',
    boxShadow: 'inset 0px 0px 50px 20px rgba(70,70,70,0.20)',
    // right: '74px',
    // borderRadius: '20px',
    padding: '15px 10px',
    color: '#e2e2e2',
  },
  projectDir: {
    color: '#3f3f3f',
    direction: 'rtl',
    zIndex: '100',
    '& >*': {
      direction: 'ltr',
    },
  },
  projectTitle: {
    letterSpacing: '-3px',
    fontWeight: '600',
    color: '#b84848',
    paddingBottom: '20px',
  },
  technology: {
    color: '#07386a',
  },
}));
let avidmath = {
  name: 'Avid Math',
  webName: 'AvidMath.com',
  link: 'https://avidmath.com',
  desc:
    'Avid Math solves industry and custom formulas/equations for mathematics & engineering calculations without using traditional industry software, or scientific calculators prone to human error. <br/> Avid Math automatically detects variables then solves the formula/equation, for any variable, both numerically and symbolically and allows users to save them. Future releases include 2D & 3D graphing and solving integrals and systems of differential equations with paid subscription.',
  techs: [
    'React.js',
    'Node.js',
    'PostgreSQL',
    'Sequelize',
    'Redux.js',
    'Material UI',
  ],
  image: imageAvidMath,
  details: 'Math Engine & Formula Management System',
};
let Auxilium = {
  name: 'Auxilium',
  link: 'https://auxilium.sidiki.dev',
  desc:
    'Tracks bugs/updates for AvidMath and other apps. This app supports project/bug/user image uploads, comments, and user profile and notifications, with in-depth bug history of any changes to bug details, status, or assignee. Upcoming release will integrate with AvidMath.com for the end user to report bugs, and will be open-sourced, supporting email notifications, 2-factor auth, user history, six user roles, and more.',
  techs: ['React.js', 'Jest', 'MongoDB', 'Mongoose', 'Node.js', 'Redux.js'],
  image: imageAuxilium,
  details: 'Bug Tracking System',
};

let ChatBloom = {
  name: 'ChatBloom',
  link: 'https://chatbloom.sidiki.dev/',
  desc:
    'ChatBloom is a chat application with a public chat room to meet people. On it, you can chat with Maan right away! Developed using Socket.IO and React',
  techs: ['Socket.IO', 'React.js', 'Express.js', 'Node.js', 'Sass', 'Jest'],
  image: imagechat,
  details: 'A Public Chat Application made with Socket.IO',
};

let ScholarVid = {
  name: 'ScholarVid',
  link: 'https://scholarvid.sidiki.dev/',
  desc:
    'A video conferencing app that allows group-watching a YouTube video while giving participants the ability to play/pause the video simultaneously across all participants for discussion and debate.',
  techs: ['WebRTC', 'Socket.IO', 'React.js', 'MongoDB', 'Node.js', 'Redux.js'],
  image: imageScholarVid,
  details: 'Video Conferencing with Simultaneous YouTube Video Playing/Pausing',
};

const Projects = () => {
  const classes = useStyles();

  return (
    <Article
      id='projects'
      background='#fafafa'
      width='lg'
      position='relative'
      height=''
    >
      <Grid
        className={classes.child}
        container
        justify='center'
        alignItems='center'
        component='section'
      >
        <Grid item xs={10} sm={10}>
          <Reveal duration={700} effect='fadeInUp'>
            <Typography variant='h2' color='primary' className={classes.title}>
              <Typography
                variant='h4'
                component='span'
                color='secondary'
                className={classes.numerical}
              >
                II.
              </Typography>
              <Box
                component='span'
                // letterSpacing={-9}
                className={classes.aboutme}
              >
                Recent Projects
              </Box>
            </Typography>
          </Reveal>
          <Reveal duration={700} effect='fadeInUp'>
            <ProjectItemRight project={avidmath} />
          </Reveal>
          <Reveal duration={700} effect='fadeInUp'>
            <ProjectItemLeft project={Auxilium} />
          </Reveal>
          <Reveal duration={700} effect='fadeInUp'>
            <ProjectItemRight project={ChatBloom} />
          </Reveal>

          <Reveal duration={700} effect='fadeInUp'>
            <ProjectItemLeft project={ScholarVid} />
          </Reveal>

          {/* <ProjectItemRight project={avidmath} /> */}
          {/* <ProjectItemLeft project={avidmath} /> */}
        </Grid>
      </Grid>
      {/* The current version is capable of solving equations and functions numerically and symbolically  I am currently working on its next release which will include: 2D and 3D function graphing,  */}
    </Article>
  );
};

export default Projects;

// // import logo from './logo.svg';
// // import './App.css';
// import { Fragment } from 'react';
// import Card from './Card';
// const Projects = () => {
//   return (
//     <main>
//       <article>
//         <h2>AvidMath.com</h2>
//         <img />
//         <section>
//           <h3>overview</h3>
//           <p></p>
//         </section>
//         <section>
//           <h3>features</h3>
//           <p></p>
//         </section>
//         <section>
//           <h3>technologies</h3>
//           <p></p>
//         </section>
//       </article>
//       <article>
//         <h2>Auxilium.com</h2>
//         <img />
//         <section>
//           <h3>overview</h3>
//           <p></p>
//         </section>
//         <section>
//           <h3>features</h3>
//           <p></p>
//         </section>
//         <section>
//           <h3>technologies</h3>
//           <p></p>
//         </section>
//       </article>
//       <article>
//         <h2>DiscordVid.com</h2>
//         <img />
//         <section>
//           <h3>overview</h3>
//           <p></p>
//         </section>
//         <section>
//           <h3>features</h3>
//           <p></p>
//         </section>
//         <section>
//           <h3>technologies</h3>
//           <p></p>
//         </section>
//       </article>
//       <article>
//         <h2>CSSPowers</h2>
//         <img />
//         <section>
//           <h3>overview</h3>
//           <p></p>
//         </section>
//         <section>
//           <h3>features</h3>
//           <p></p>
//         </section>
//         <section>
//           <h3>technologies</h3>
//           <p></p>
//         </section>
//       </article>
//     </main>
//   );
// };

// export default Projects;
