// import './Aboutme.css';

import { Fragment } from 'react';
import { makeStyles, Typography, Box, Grid, Button } from '@material-ui/core';
import Article from './Article';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Avatar from '@material-ui/core/Avatar';
import image from './images/aboutme.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Reveal from 'react-reveal/Reveal';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  Name: { whiteSpace: 'nowrap' },
  '@keyframes example': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(720deg)' },
  },
  '@keyframes example2': {
    '0%': { transform: 'scale(1)' },
    '70%': { transform: 'scale(1.9)' },

    '100%': { transform: 'scale(1)' },
  },
  media: {
    height: 0,
    paddingTop: '50%', // 16:9
    backgroundSize: 'contain',
    // opacity: '20%',
    // transition: 'opacity linear 0.5s',
    // transition: ' 0.70s',
    // transition: ' 0.70s',
    // animationName: 'example',
    // animationDuration: '4s',
    // animation: '6s $example2 infinite ',

    '&:hover': {
      animation: '0.7s $example',
      // animation: rotate 1s, spin 3s;

      // animationTimingFunction: 'ease',
      // transform: 'rotate(20deg)',
      // transition: ' 0.7s',
    },
  },
  container: { position: 'relative', maxHeight: '120vh' },
  child: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    '& li': {
      listStyleType: 'none',
      margin: 0,
      padding: '0',
      color: '#707070',
    },
    // textAlign: 'center',

    [theme.breakpoints.down('md')]: {},
    // [theme.breakpoints.up('lg')]: {
    //   bottom: '0',
    // },
  },
  button: {
    marginTop: '25px',
    padding: '6px 15px',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(100%)',
    },
  },
  aboutme: {
    letterSpacing: 'calc(-3px - 0.1vw)',
    paddingLeft: '10px',
  },
  title: {
    [theme.breakpoints.up('md')]: {
      '&:after': {
        top: '-29px',
        width: '263px',
        height: '1px',
        content: "''",
        display: 'block',
        position: 'relative',
        marginLeft: '360px',
        backgroundColor: '#c3c3c3',
      },
    },
  },
  sup: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingLeft: '3px',
  },
  iconColor: {
    color: theme.palette.secondary.light,
  },
  list: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  numerical: {
    fontFamily: 'Times New Roman',
  },
}));

let skills = [
  'React.js',
  'Redux',
  'Jest',
  'WebRTC',
  'HTML',
  'CSS (& SASS)',
  'Styled Components',
  'Material-UI',
  'Bootstrap (& React Bootstrap)',
];

let skills2 = [
  'Node.js',
  'PostgreSQL',
  'MongoDB',
  'MySQL',
  'Sequelize',
  'Git',
  'Socket.IO',
  'Express.js',
  'Heroku CLI',
];
const Contact = () => {
  const classes = useStyles();

  return (
    <Article
      id='contact'
      background='#f5f5f5'
      width='md'
      position='relative'
      minHeight='80vh'
      // boxShadow='-4px -5px 24px 4px rgba(0,0,0,0.16)'
    >
      <Grid
        className={classes.child}
        container
        justify='center'
        alignItems='center'
        component='section'
      >
        <Grid item xs={10} sm={8}>
          <Reveal duration={700} effect='fadeInUp'>
            <Typography variant='h2' color='primary' className={classes.title}>
              <Typography
                variant='h4'
                component='span'
                color='secondary'
                className={classes.numerical}
              >
                III.
              </Typography>
              <Box
                component='span'
                // letterSpacing={-9}
                className={classes.aboutme}
              >
                Get in Touch
              </Box>
            </Typography>

            <Typography component='p' variant='h6'>
              <Box fontWeight={500} color='#707070' pt={1}>
                I am currently looking for new opportunities.
                <br /> Interested in hiring me? have a question? or just want to
                say hi?
                <br />
              </Box>
            </Typography>
            <Grid container justify='center'>
              <Grid item>
                <Button
                  className={classes.button}
                  size='large'
                  color='secondary'
                  variant='outlined'
                  classes={{ contained: classes.button }}
                  href='mailto:maan.sidiki@gmail.com'
                >
                  Email Me{' '}
                </Button>
              </Grid>
            </Grid>
          </Reveal>
        </Grid>
      </Grid>
    </Article>
  );
};

export default Contact;
