// import './Aboutme.css';

import { Fragment } from 'react';
import { ReactComponent as ReactLogo } from './images/signature.svg';

import Reveal from 'react-reveal/Reveal';
import { makeStyles, Typography, Box, Grid, Button } from '@material-ui/core';
import Article from './Article';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Avatar from '@material-ui/core/Avatar';
import image from './images/aboutme.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  Name: { whiteSpace: 'nowrap' },
  '@keyframes example': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(720deg)' },
  },
  '@keyframes example2': {
    '0%': { transform: 'scale(1)' },
    '70%': { transform: 'scale(1.9)' },

    '100%': { transform: 'scale(1)' },
  },
  media: {
    height: 0,
    paddingTop: '50%', // 16:9
    backgroundSize: 'contain',
    // opacity: '20%',
    // transition: 'opacity linear 0.5s',
    // transition: ' 0.70s',
    // transition: ' 0.70s',
    // animationName: 'example',
    // animationDuration: '4s',
    // animation: '6s $example2 infinite ',

    '&:hover': {
      animation: '0.7s $example',
      // animation: rotate 1s, spin 3s;

      // animationTimingFunction: 'ease',
      // transform: 'rotate(20deg)',
      // transition: ' 0.7s',
    },
  },
  container: { position: 'relative', maxHeight: '120vh' },
  child: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    '& li': {
      listStyleType: 'none',
      margin: 0,
      padding: '0',
      color: '#707070',
    },
    // textAlign: 'center',

    [theme.breakpoints.down('md')]: {},
    // [theme.breakpoints.up('lg')]: {
    //   bottom: '0',
    // },
  },
  button: {
    marginTop: '15px',
    padding: '6px 15px',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(100%)',
    },
  },
  aboutme: {
    letterSpacing: 'calc(-3px - 0.1vw)',
    paddingLeft: '10px',
  },
  title: {
    [theme.breakpoints.up('md')]: {
      '&:after': {
        top: '-29px',
        width: '263px',
        height: '1px',
        content: "''",
        display: 'block',
        position: 'relative',
        marginLeft: '340px',
        backgroundColor: '#c3c3c3',
      },
    },
  },
  sup: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingLeft: '3px',
  },
  iconColor: {
    color: theme.palette.secondary.light,
  },
  list: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  numerical: {
    fontFamily: 'Times New Roman',
  },

  twitter: {
    transition: '0.5s',
    '&:hover': {
      color: '#00acee',
      borderRadius: '20px',
    },
  },

  linkedin: {
    transition: '0.5s',
    '&:hover': {
      color: '#0e76a8',
      borderRadius: '20px',
    },
  },
  github: {
    transition: '0.5s',
    '&:hover': {
      color: '#5e5e5e',
      borderRadius: '20px',
    },
  },
  removelarge: {
    [theme.breakpoints.up('lg')]: { display: 'none' },
  },
}));

let skills = [
  'React.js',
  'Redux',
  'Jest',
  'WebRTC',
  'HTML',
  'CSS (& SASS)',
  'Styled Components',
  'Material-UI',
  'Bootstrap (& React Bootstrap)',
];

let skills2 = [
  'Node.js',
  'PostgreSQL',
  'MongoDB',
  'MySQL',
  'Sequelize',
  'Git',
  'Socket.IO',
  'Express.js',
  'Heroku CLI',
];
const Footer = () => {
  const classes = useStyles();

  return (
    <Container
      component='footer'
      style={{
        position: 'relative',
        // height: '250px',
      }}
      width='md'
    >
      <Grid contianer justify='center' direction='row'>
        <Grid
          className={classes.removelarge}
          item
          xs={12}
          style={{ paddingTop: '50px' }}
        >
          <Grid container justify='center' direction='row' spacing={3}>
            <Grid item>
              <a href='https://github.com/maandhia' target='_blank'>
                <GitHubIcon className={classes.github} fontSize='large' />
              </a>
            </Grid>
            <Grid item>
              <a href='https://www.linkedin.com/in/maandhia/' target='_blank'>
                <LinkedInIcon className={classes.linkedin} fontSize='large' />
              </a>
            </Grid>
            <Grid item>
              <a href='https://twitter.com/MaanDhia' target='_blank'>
                <TwitterIcon className={classes.twitter} fontSize='large' />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            paddingTop: '50px',
            textAlign: 'center',
            userSelect: 'none',
          }}
          item
          xs={12}
        >
          <Reveal duration={700} effect='fadeInUp'>
            <ReactLogo />
          </Reveal>
        </Grid>
        <Grid style={{ padding: '50px 0' }} item xs={12}>
          <Typography align='center' variant='caption' display='block'>
            Designed and developed by Maan Sidiki © 2020
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
