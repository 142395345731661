// import './Aboutme.css';

import { Fragment } from 'react';
import { makeStyles, Typography, Box, Grid, Button } from '@material-ui/core';
import Article from './Article';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Avatar from '@material-ui/core/Avatar';
import image from './images/aboutme.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Reveal from 'react-reveal/Reveal';
import logoImage from './images/sdcsdcs.png';
import logoImageTop from './images/sdcsdcs2.png';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import * as Scroll from 'react-scroll';

import { Icon, InlineIcon } from '@iconify/react';
import bxlNodejs from '@iconify-icons/bx/bxl-nodejs';
import bxlReact from '@iconify-icons/bx/bxl-react';
import bxlRedux from '@iconify-icons/bx/bxl-redux';
import nodeIcon from '@iconify-icons/fa-brands/node';
import expressIcon from '@iconify-icons/logos/express';
import postgresqlIcon from '@iconify-icons/simple-icons/postgresql';
import mongodbIcon from '@iconify-icons/simple-icons/mongodb';
import mysqlIcon from '@iconify-icons/simple-icons/mysql';
import sequelizeIcon from '@iconify-icons/file-icons/sequelize';
import bxlGit from '@iconify-icons/bx/bxl-git';
import styledcomponentsIcon from '@iconify-icons/file-icons/styledcomponents';
import webrtcIcon from '@iconify-icons/simple-icons/webrtc';
import socketIo from '@iconify-icons/cib/socket-io';
import html5Icon from '@iconify-icons/simple-icons/html5';
import bxlCss3 from '@iconify-icons/bx/bxl-css3';
import sassAlt from '@iconify-icons/cib/sass-alt';
import jestIcon from '@iconify-icons/cib/jest';
import herokuIcon from '@iconify-icons/cib/heroku';
import materialUi from '@iconify-icons/simple-icons/material-ui';
import bxlBootstrap from '@iconify-icons/bx/bxl-bootstrap';
import npmIcon from '@iconify-icons/cib/npm';
import Tilt from 'react-tilt';

let ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  Name: { whiteSpace: 'nowrap' },
  '@keyframes example': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  '@keyframes example2': {
    '0%': { transform: 'scale(1)' },
    '70%': { transform: 'scale(1.9)' },

    '100%': { transform: 'scale(1)' },
  },

  media: {
    animation: '50s $example infinite linear',

    height: '200px',
    width: '200px',
    transition: 'all 1s',
  },
  rotatingLogo: {
    position: 'relative',
    marginLeft: '100px',
    [theme.breakpoints.down('sm')]: { marginLeft: '0px', marginTop: '60px' },
  },
  media2: {
    // animation: '30s $example infinite linear',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '200px',
    width: '200px',
    transition: 'all 1s',
  },

  container: { position: 'relative', maxHeight: '120vh' },
  child: {
    padding: '80px 0',
    // position: 'absolute',
    // top: '50%',
    // transform: 'translateY(-50%)',
    '& li': {
      listStyleType: 'none',
      margin: 0,
      padding: '0',
      color: '#707070',
    },
    // textAlign: 'center',

    [theme.breakpoints.down('md')]: {},
    // [theme.breakpoints.up('lg')]: {
    //   bottom: '0',
    // },
  },
  button: {
    marginTop: '15px',
    padding: '6px 10px',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(100%)',
    },
  },
  aboutme: {
    letterSpacing: 'calc(-3px - 0.1vw)',
    paddingLeft: '10px',
  },
  title: {
    [theme.breakpoints.up('md')]: {
      '&:after': {
        top: '-29px',
        width: '259px',
        height: '1px',
        content: "''",
        display: 'block',
        position: 'relative',
        marginLeft: '280px',
        backgroundColor: '#c3c3c3',
      },
    },
  },
  sup: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingLeft: '3px',
    paddingRight: '3px',
    cursor: 'pointer',
  },
  iconColor: {
    color: theme.palette.secondary.light,
  },
  list: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  numerical: {
    fontFamily: 'Times New Roman',
  },
  skillsContainer: {
    '& >div': {
      padding: '40px 0',
      position: 'relative',
      minHeight: '120px',
      transition: 'all 0.5s',

      '&:hover': {
        transition: 'all 0.5s',
        transform: 'scale(1.2)',
        '& path,circle': {
          transition: 'all 0.5s',
          fill: theme.palette.secondary.main,
          color: theme.palette.secondary.main,
        },
        '& div': {
          transition: 'all 0.5s',
          color: theme.palette.secondary.main,
        },
      },

      '& >svg': {
        '& >path,circle': {
          transition: 'all 0.5s',
          fill: '#444',
          color: '#444',
        },
      },
      '& section >svg': {
        '& >path': {
          transition: 'all 0.5s',
          fill: '#444',
          color: '#444',
        },
      },

      '& div': {
        transition: 'all 0.5s',
        right: '50%',
        transform: 'translateX(50%)',
        position: 'absolute',
        bottom: '0',
        color: '#787878',
        userSelect: 'none',
      },
    },
  },

  // buzz: {
  //   transition: 'all 0.5s',

  //   '&:hover': {
  //     // animation: '0.7s $buzzing',
  //     transition: 'all 0.3s',
  //     transform: 'scale(1.1)',
  //   },
  // },
}));

let skills = [
  'React.js',
  'Redux',
  'Node.js',
  'PostgreSQL',
  'MongoDB',
  'MySQL',
  'Sequelize',
  'Git',
  'Styled Components',
];

let skills2 = [
  'Express.js',
  'WebRTC',
  'Socket.IO',
  'HTML',
  'CSS (& SASS)',
  'Jest',
  'Heroku CLI',
  'Material-UI',
  'Bootstrap (& React Bootstrap)',
];
const AboutMe = () => {
  const classes = useStyles();

  return (
    <Article
      id='about'
      background='#f5f5f5'
      width='md'
      position='relative'
      boxShadow='rgb(0 0 0 / 16%) 0px -4px 93px 4px'
      height=''
    >
      <Grid
        className={classes.child}
        container
        justify='center'
        alignItems='center'
        component='section'
      >
        <Grid item xs={10} sm={7}>
          <Reveal duration={700} effect='fadeInUp'>
            <Typography variant='h2' color='primary' className={classes.title}>
              <Typography
                variant='h4'
                component='span'
                color='secondary'
                className={classes.numerical}
              >
                I.
              </Typography>
              <Box
                component='span'
                // letterSpacing={-9}
                className={classes.aboutme}
              >
                About Me
              </Box>
            </Typography>

            <Typography component='p' variant='body1'>
              <Box fontWeight={500} color='#707070' pt={1}>
                I enjoy creating modern web applications, websites, and anything
                in between.
              </Box>
            </Typography>
            <Typography component='p' variant='body1'>
              <Box fontWeight={500} color='#707070' pt={1}>
                I love connecting science and web dev
                <ScrollLink to='projects' smooth={true} duration={700}>
                  <sup className={classes.sup}>1</sup>
                </ScrollLink>
                . My goal is to write modular, efficient code to produce
                user-friendly, performant experiences.
              </Box>
            </Typography>
            <Typography component='p' variant='body1'>
              <Box fontWeight={500} color='#707070' pt={1}>
                My most used languages, libraries, and frameworks include:
              </Box>
            </Typography>
            <Reveal duration={1000} effect='fadeInUp'>
              <Grid
                style={{ margin: '0 auto', fontSize: '50px' }}
                container
                justify='center'
                alignItems='flex-end'
                className={classes.skillsContainer}
              >
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={bxlNodejs}
                    style={{ display: 'block', margin: 'auto' }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    JavaScript
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={bxlReact}
                    style={{ display: 'block', margin: 'auto' }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    React.js
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={bxlRedux}
                    style={{ display: 'block', margin: 'auto' }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Redux
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={nodeIcon}
                    style={{ display: 'block', margin: 'auto' }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Node.js
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={expressIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '28px',
                      marginTop: '15px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Express.js
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={postgresqlIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginTop: '10px',
                      // fontSize: '46px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    PostgreSQL
                  </div>
                </Grid>

                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={mongodbIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginTop: '10px',
                      // fontSize: '46px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    MongoDB
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={mysqlIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      // marginTop: '10px',
                      fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    MySQL
                  </div>
                </Grid>

                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={sequelizeIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      // marginTop: '10px',
                      fontSize: '45px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Sequelize
                  </div>
                </Grid>

                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={bxlGit}
                    style={{
                      display: 'block',
                      margin: 'auto',

                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Git & GitHub
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <section
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '45px',
                      textAlign: 'center',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  >
                    <Icon
                      icon={bxlCss3}
                      style={
                        {
                          // display: 'block',
                          // margin: 'auto',
                          // fontSize: '45px',
                          // marginTop: '10px',
                          // fontSize: '60px',
                        }
                      }
                    />
                    <Icon
                      icon={sassAlt}
                      style={
                        {
                          // display: 'block',
                          // margin: 'auto',
                          // fontSize: '45px',
                          // marginTop: '10px',
                          // fontSize: '60px',
                        }
                      }
                    />
                  </section>
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    CSS & Sass
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={html5Icon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '40px',

                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    HTML
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={socketIo}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '42px',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Socket.IO
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={jestIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '42px',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Jest
                  </div>
                </Grid>

                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={materialUi}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '42px',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Material UI
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={herokuIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '42px',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Heroku CLI
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={styledcomponentsIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '42px',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    Styled Components
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={webrtcIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '42px',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    WebRTC
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={bxlBootstrap}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontSize: '42px',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    BootStrap
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={3}
                  // style={{ border: 'black solid 1px' }}
                >
                  <Icon
                    icon={npmIcon}
                    style={{
                      display: 'block',
                      margin: 'auto',
                      // fontSize: '42px',
                      // marginTop: '10px',
                      // fontSize: '60px',
                    }}
                  />
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                    }}
                  >
                    npm
                  </div>
                </Grid>
              </Grid>
            </Reveal>
          </Reveal>
        </Grid>
        <Grid item className={classes.rotatingLogo}>
          <CardMedia
            className={classes.media}
            image={logoImage}
            title='Maan Sidiki'
          />
          <CardMedia
            className={classes.media2}
            image={logoImageTop}
            title='Maan Sidiki'
          />
        </Grid>
      </Grid>
    </Article>
  );
};

export default AboutMe;
