// import logo from './logo.svg';
import './App.css';
// import { Fragment, useEffect } from 'react';
import React, { Fragment, useState } from 'react';
import Appbar from './Appbar';
import Header from './Header';
import Projects from './Projects';
import AboutMe from './AboutMe';
import Contact from './Contact';
// import './fonts/Regular.woff';
import Footer from './Footer';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { Container, Typography, Grid } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  aside: {
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      position: 'fixed',
      // transform: 'rotate(-90deg)',
      // bottom: '44px',
      // left: '-135px',
      zIndex: '5000',
      '&:after': {
        content: '""',
        position: 'fixed',
        transform: 'translateX(-31px)',
        right: '0',
        bottom: '0',
        display: 'block',
        width: '1px',
        height: '259px',
        backgroundColor: theme.palette.primary.main,
      },
    },
    display: 'none',

    // [theme.breakpoints.up('md')]: {
    //   '&:before': {
    //     top: '-170px',
    //     width: '267px',
    //     height: '1px',
    //     content: "''",
    //     display: 'block',
    //     position: 'relative',
    //     marginLeft: '145px',
    //     backgroundColor: '#242424',
    //   },
    // },
  },
  twitter: {
    transition: '0.5s',
    '&:hover': {
      color: '#00acee',
      // background: 'rgb(250, 250, 250)',
      borderRadius: '20px',
    },
  },

  linkedin: {
    transition: '0.5s',
    '&:hover': {
      color: '#0e76a8',
      // background: 'rgb(250, 250, 250)',
      borderRadius: '20px',
    },
  },
  github: {
    transition: '0.5s',
    '&:hover': {
      color: '#5e5e5e',
      // background: 'rgb(250, 250, 250)',
      borderRadius: '20px',
    },
  },
  social: {
    position: 'fixed',
    bottom: '203px',
    display: 'flex',
    flexDirection: 'column',
    height: '126px',
    justifyContent: 'space-between',
    marginBottom: '70px',
    right: '20px',
    color: theme.palette.success.main,
  },
}));

const Addition = (props) => {
  const classes = useStyles(props);

  return (
    <Box component='aside' className={classes.aside}>
      <div container alignItems='center' className={classes.social}>
        <a href='https://github.com/maandhia' target='_blank'>
          <GitHubIcon className={classes.github} />
        </a>
        <a href='https://www.linkedin.com/in/maandhia/' target='_blank'>
          <LinkedInIcon className={classes.linkedin} />
        </a>

        <a href='https://twitter.com/MaanDhia' target='_blank'>
          <TwitterIcon className={classes.twitter} />
        </a>
      </div>
    </Box>
  );
};

export default Addition;
