// import logo from './logo.svg';
// import './App.css';
// import { Fragment, useEffect } from 'react';
import React, { Fragment, useState } from 'react';
// import Appbar from './Appbar';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import image from './images/aboutme.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Box from '@material-ui/core/Box';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Divider } from '@material-ui/core';
import * as Scroll from 'react-scroll';

import { Container, Typography, Grid } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
let ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  aside: {
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      position: 'fixed',
      // transform: 'rotate(-90deg)',
      // bottom: '44px',
      // left: '-135px',
      '&:after': {
        content: '""',
        position: 'fixed',
        transform: 'translateX(-31px)',
        right: '0',
        bottom: '0',
        display: 'block',
        width: '1px',
        height: '259px',
        backgroundColor: theme.palette.success.main,
      },
    },
    display: 'none',

    // [theme.breakpoints.up('md')]: {
    //   '&:before': {
    //     top: '-170px',
    //     width: '267px',
    //     height: '1px',
    //     content: "''",
    //     display: 'block',
    //     position: 'relative',
    //     marginLeft: '145px',
    //     backgroundColor: '#242424',
    //   },
    // },
  },
  transition: { animation: '1s $example2' },

  twitter: {
    transition: '0.5s',
    '&:hover': {
      color: '#00acee',
      // background: 'rgb(250, 250, 250)',
      borderRadius: '20px',
    },
  },

  linkedin: {
    transition: '0.5s',
    '&:hover': {
      color: '#0e76a8',
      // background: 'rgb(250, 250, 250)',
      borderRadius: '20px',
    },
  },
  github: {
    transition: '0.5s',
    '&:hover': {
      color: '#5e5e5e',
      // background: 'rgb(250, 250, 250)',
      borderRadius: '20px',
    },
  },
  social: {
    position: 'fixed',
    bottom: '203px',
    display: 'flex',
    flexDirection: 'column',
    height: '126px',
    justifyContent: 'space-between',
    marginBottom: '70px',
    right: '20px',
    color: '#05386B',
  },
  list: {
    position: 'fixed',
    padding: '0px 20px',
    [theme.breakpoints.down('sm')]: { padding: '14px 20px' },

    backgroundColor: theme.palette.success.main,
    zIndex: 1000,
    top: '0',
    '& li': {
      color: theme.palette.common.white,
      padding: '18px 20px',
      cursor: 'pointer',
    },
    '& .hamburger': {
      color: theme.palette.common.white,
      padding: '0px 20px',
      cursor: 'pointer',
    },
  },
  mobileList: {
    position: 'fixed',
    padding: '20px 20px',
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
    zIndex: 999,
    top: '55.2px',
    height: 'calc(100vh - 55.2px)',
    animation: '.3s $example2',
    [theme.breakpoints.up('md')]: { display: 'none' },
    '& .link': {
      cursor: 'pointer',
      padding: '10px 0',
    },

    // '& li': {
    //   color: theme.palette.common.white,
    //   padding: '0 5px',
    // },
  },
  media: {
    height: '60px',
    width: '60px',
    marginLeft: '20px', // 16:9
    backgroundSize: 'contain',
    position: 'absolute',
    top: '5px',
    cursor: 'pointer',
    animation: '0.7s $example3',

    '&:hover': {
      animation: '0.7s $example',
    },
  },
  '@keyframes example': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(720deg)' },
  },
  '@keyframes example2': {
    '0%': { opacity: '0%' },
    '100%': { opacity: '100%' },
  },
  '@keyframes example3': {
    '0%': { transform: 'rotate(720deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },

  removeWhenSmall: {
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  removeWhenLarge: {
    [theme.breakpoints.up('md')]: { display: 'none' },
  },
}));

const Appbar = (props) => {
  const classes = useStyles(props);
  const [menu, setMenu] = useState(false);

  return (
    <Box component='nav' className={classes.transition}>
      <Grid
        className={classes.list}
        component='ul'
        container
        alignItems='center'
        justify='space-between'
      >
        <Grid item>
          <Grid
            alignItems='center'
            component='div'
            container
            justify='flex-start'
          >
            <Grid item>
              <ScrollLink to='header' smooth={true} duration={300}>
                <CardMedia
                  className={classes.media}
                  image={image}
                  title='Maan Sidiki'
                />
              </ScrollLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            justify='flex-end'
            alignItems='center'
            className={classes.removeWhenSmall}
          >
            <Grid item>
              <ScrollLink to='about' smooth={true} duration={700}>
                <Box component='li' fontSize='h6.fontSize' fontFamily='PT Sans'>
                  ABOUT
                </Box>
              </ScrollLink>
            </Grid>
            <Grid item>
              <ScrollLink to='projects' smooth={true} duration={700}>
                <Box component='li' fontSize='h6.fontSize' fontFamily='PT Sans'>
                  PROJECTS
                </Box>
              </ScrollLink>
            </Grid>
            {/* <Grid item>
              <ScrollLink to='testimonials' smooth={true} duration={700}>
                <Box
                  component='li'
                  fontSize='body2.fontSize'
                  fontFamily='PT Sans'
                >
                  Testimonials
                </Box>
              </ScrollLink>
            </Grid> */}
            <Grid item>
              <ScrollLink to='contact' smooth={true} duration={700}>
                <Box component='li' fontSize='h6.fontSize' fontFamily='PT Sans'>
                  CONTACT
                </Box>
              </ScrollLink>
            </Grid>
          </Grid>
          <Grid
            container
            justify='flex-end'
            alignItems='center'
            className={classes.removeWhenLarge}
          >
            <Grid item>
              <Box
                className='hamburger'
                fontSize='body2.fontSize'
                fontFamily='PT Sans'
              >
                {!menu && (
                  <MenuIcon
                    onClick={() => {
                      setMenu(true);
                    }}
                  />
                )}
                {menu && (
                  <CloseIcon
                    onClick={() => {
                      setMenu(false);
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {menu && (
        <Grid
          className={classes.mobileList}
          component='ul'
          container
          alignItems='center'
          justify='center'
        >
          <Grid item xs={12}>
            <ScrollLink to='about' smooth={true} duration={700}>
              <Box
                className='link'
                onClick={() => {
                  setMenu(false);
                }}
                component='li'
                fontSize='h6.fontSize'
                fontFamily='PT Sans'
              >
                I. About
              </Box>
            </ScrollLink>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ScrollLink to='projects' smooth={true} duration={700}>
              <Box
                className='link'
                onClick={() => {
                  setMenu(false);
                }}
                component='li'
                fontSize='h6.fontSize'
                fontFamily='PT Sans'
              >
                II. Projects
              </Box>
            </ScrollLink>
          </Grid>

          {/* <Grid item xs={12}>
            <ScrollLink to='testimonials' smooth={true} duration={700}>
              <Box
                className='link'
                onClick={() => {
                  setMenu(false);
                }}
                component='li'
                fontSize='h6.fontSize'
                fontFamily='PT Sans'
              >
                III. Testimonials
              </Box>
            </ScrollLink>
          </Grid> */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ScrollLink to='contact' smooth={true} duration={700}>
              <Box
                className='link'
                onClick={() => {
                  setMenu(false);
                }}
                component='li'
                fontSize='h6.fontSize'
                fontFamily='PT Sans'
              >
                III. Contact
              </Box>
            </ScrollLink>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Appbar;
