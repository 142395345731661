// import './Aboutme.css';

import { Fragment } from 'react';
import { makeStyles, Typography, Box, Grid, Button } from '@material-ui/core';
import Article from './Article';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Avatar from '@material-ui/core/Avatar';
import image from './images/avidmath.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useTheme } from '@material-ui/core/styles';
import Reveal from 'react-reveal/Reveal';

const useStyles = makeStyles((theme) => ({
  projectImage: {
    background: (props) => `url(${props.image})`,
    backgroundSize: (props) => 'cover',
    borderRadius: '20px',
    height: 0,
    paddingTop: '56.9%',
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0px 0px 46px -15px rgb(70 70 70 / 22%)',
    },
  },
  backgroundColor: {
    height: '100%',
    width: '100%',
    backgroundColor: '#05386b',
    opacity: '20%',
    position: 'absolute',
    borderRadius: '20px',
    transition: 'all 0.5s',

    '&:hover': {
      opacity: '60%',
      transition: 'all 0.5s',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  projectDesc: {
    minWidth: '130%',
    backgroundColor: '#05386b',
    boxShadow: 'inset 0px 0px 50px 20px rgba(70,70,70,0.20)',

    padding: '15px 10px',
    color: '#e2e2e2',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      margin: '20px 0 0 0',
    },
  },
  projectDir: {
    color: '#3f3f3f',
    direction: 'rtl',
    zIndex: '100',
    '& >*': {
      direction: 'ltr',
    },
    [theme.breakpoints.down('sm')]: {
      direction: 'inherit',
      '& >*': {
        direction: 'inherit',
      },
    },
  },
  projectTitle: {
    letterSpacing: 'calc(-2px - 0.1vw)',
    fontWeight: '600',
    color: '#ac3b61',
    // paddingBottom: '20px',
  },
  technology: {
    color: '#07386a',
  },
  removeMed: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  displayMed: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  ProjectDetails: {
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '10px',
    },
    color: '#0b3967',
    letterSpacing: '0',
  },
}));

const ProjectItemRight = ({ project: props }) => {
  const classes = useStyles(props);
  const theme = useTheme();
  // console.log(theme.breakpoints.up('sm'));

  return (
    <Fragment>
      <Grid
        container
        justify='center'
        alignItems='center'
        component='section'
        style={{ padding: '100px 0' }}
        className={classes.removeMed}
      >
        <Grid item xs={7} style={{ position: 'relative' }}>
          <a target='_blank' href={props.link}>
            <Box className={classes.backgroundColor} title={props.name} />
            <Box className={classes.projectImage} title={props.name} />
          </a>
        </Grid>
        <Grid className={classes.projectDir} item xs={5}>
          <Typography
            variant='h3'
            align='right'
            className={classes.projectTitle}
          >
            <a target='_blank' href={props.link}>
              {props.webName || props.name}
            </a>
          </Typography>
          <Typography
            variant='body1'
            align='right'
            className={classes.ProjectDetails}
          >
            {props.details}
          </Typography>
          <Box className={classes.projectDesc}>
            <Typography variant='body1' align='right'>
              {props.desc.split('<br/>')[0]}
              {props.desc.split('<br/>')[1] && <br />}
              {props.desc.split('<br/>')[1]}
            </Typography>
          </Box>
          <Grid
            container
            justify='center'
            alignItems='center'
            component='section'
          >
            <Grid item xs={4}></Grid>
            <Grid item xs={8} style={{ margin: '20px 0' }}>
              <Grid
                container
                justify='space-between'
                className={classes.technology}
              >
                {props.techs.map((tech, index) => {
                  return (
                    <Grid item xs={4}>
                      <Typography
                        variant='body2'
                        align={index == 2 || index == 5 ? 'right' : 'center'}
                        style={index < 3 ? { paddingBottom: '5px' } : {}}
                      >
                        {tech}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <a target='_blank' href={props.link}>
            <OpenInNewIcon style={{ color: '#ac3b61' }} />
          </a>
        </Grid>
      </Grid>
      {/* seperator










*/}
      <Grid
        className={classes.displayMed}
        container
        justify='center'
        alignItems='center'
        component='section'
        style={{ padding: '40px 0' }}
      >
        <Grid
          item
          xs={12}
          container
          justify='space-between'
          alignItems='flex-end'
          style={{ padding: '20px 0' }}
        >
          {' '}
          <Grid item>
            <Typography
              variant='h3'
              component='span'
              className={classes.projectTitle}
            >
              <a target='_blank' href={props.link}>
                {props.webName || props.name}
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <a target='_blank' href={props.link}>
              <OpenInNewIcon style={{ color: '#ac3b61' }} />
            </a>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='body1'
              // align='right'
              className={classes.ProjectDetails}
            >
              {props.details}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ margin: '15px 0' }}>
            <Grid
              container
              justify='space-around'
              className={classes.technology}
            >
              {props.techs.map((tech, index) => {
                return (
                  <Grid item>
                    <Typography
                      variant='body2'
                      noWrap
                      align='center'
                      style={{
                        padding: '3px',
                        marging: '3px',
                        border: '1px solid #c7c7c7',
                        borderRadius: '10px',
                      }}
                    >
                      {tech}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ position: 'relative' }}>
          <a target='_blank' href={props.link}>
            <Box className={classes.backgroundColor} title={props.name} />
            <Box classes={{ root: classes.projectImage }} title={props.name} />
          </a>
        </Grid>

        <Grid className={classes.projectDir} item xs={12}>
          <Box className={classes.projectDesc}>
            <Typography variant='body2' align='center'>
              {props.desc.replace('<br/>', '')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProjectItemRight;
