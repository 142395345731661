// import logo from './logo.svg';
// import './App.css';
import { Fragment } from 'react';
import { makeStyles, Typography, Box, Grid, Button } from '@material-ui/core';
import Article from './Article';
import image from './images/Maan.png';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import * as Scroll from 'react-scroll';
import createPalette from '@material-ui/core/styles/createPalette';

let ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  '@keyframes example': {
    '0%': { opacity: '0%' },
    '100%': { opacity: '100%' },
  },
  Name: { whiteSpace: 'nowrap' },
  media: {
    height: 0,
    paddingTop: '72.45%', // 16:9
    backgroundSize: 'contain',
    opacity: 0.95,
  },
  container: {
    animation: '1s $example',
    position: 'relative',
    maxHeight: '100vh',
  },
  child: {
    position: 'absolute',

    [theme.breakpoints.down('md')]: {
      top: '50%',
      transform: 'translateY(-50%)',
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      bottom: '0',
    },
  },
  button: {
    marginTop: '15px',
    padding: '6px 15px',
    fontSize: '18px',
    borderRadius: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(100%)',
    },
  },
  maanName: {
    letterSpacing: ' calc(-2px - 0.15vw)',
    marginBottom: '25px',
  },
  padding: {
    padding: '10px 0',
  },

  '@keyframes typing': {
    from: {
      width: '0',
    },
    to: {
      width: '421px',
    },
  },
  '@keyframes typingXS': {
    from: {
      width: '0',
    },
    to: {
      width: '290px',
    },
  },

  '@keyframes typingS': {
    from: {
      width: '0',
    },
    to: {
      width: '320px',
    },
  },
  '@keyframes typingM': {
    from: {
      width: '0',
    },
    to: {
      width: '380px',
    },
  },
  '@keyframes blinkCaret': {
    'from, to': {
      borderColor: 'transparent',
    },
    '50%': {
      borderColor: theme.palette.secondary.main,
    },
  },
  typewriter: {
    '& h1': {
      overflow: 'hidden',
      borderRight: `.08em solid ${theme.palette.secondary.main}`,
      whiteSpace: 'nowrap',
      width: '421px',
      animation:
        '$typing 2.5s steps(30, end),    $blinkCaret .75s step-end infinite',

      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
        width: '380px',
        animation:
          '$typingM 1.8s steps(30, end),    $blinkCaret .75s step-end infinite',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
        width: '320px',
        animation:
          '$typingS 1.8s steps(30, end),    $blinkCaret .75s step-end infinite',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
        width: '290px',
        animation:
          '$typingXS 1.8s steps(30, end),    $blinkCaret .75s step-end infinite',
      },

      // margin: '0 auto',
      // letterSpacing: '.15em',
    },
  },
}));
const Header = () => {
  const classes = useStyles();
  return (
    <Article
      height=''
      id='header'
      width='xl'
      useStyles={useStyles}
      minHeight='600px'
    >
      <Grid
        className={classes.child}
        container
        direction='row'
        justify='flex-end'
        alignItems='center'
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          component='section'
          className={classes.typewriter}
        >
          <Typography
            className={classes.maanName}
            color='primary'
            variant='h2'
            component='h1'
          >
            I'm{' '}
            <Box fontWeight='bold' component='span' className={classes.Name}>
              Maan Sidiki,
            </Box>
          </Typography>
          <Typography color='primary' variant='h6' component='p'>
            <Box component='span' className={classes.padding}>
              a full-stack JavaScript developer in Toronto, ON.
            </Box>
          </Typography>{' '}
          <Typography
            color='primary'
            variant='h6'
            component='p'
            className={classes.padding}
          >
            My favourite technologies are{' '}
            <Box component='span' color='secondary.main'>
              React.js
            </Box>
            ,{' '}
            <Box component='span' color='secondary.main'>
              Node.js
            </Box>
            , and{' '}
            <Box component='span' color='secondary.main'>
              PostgreSQL
            </Box>
            .
            <br />{' '}
            <span style={{ display: 'block' }} className={classes.padding}>
              I love building purposeful, user-friendly, efficient software.
            </span>
          </Typography>
          <ScrollLink to='projects' smooth={true} duration={700}>
            <Button
              className={classes.button}
              color='secondary'
              variant='contained'
              classes={{ contained: classes.button }}
            >
              View Projects
            </Button>
          </ScrollLink>
        </Grid>
        <Grid xs={false} lg={6} item component='section'>
          {/* <Card> */}
          <CardMedia
            className={classes.media}
            image={image}
            title='Maan Sidiki'
          />
          {/* </Card> */}
          {/* <img src={image} /> */}
        </Grid>
      </Grid>
    </Article>
  );
};

export default Header;
